






















































































































import {Component, Vue} from "vue-property-decorator";
import {School} from "../../entity/School";
import IMask from "imask";
import MaterialInput from "@/components/input/MaterialInput.vue";
import AlertText from "@/components/alert/AlertText.vue";
import MaterialButton from "@/components/button/MaterialButton.vue";
import Loader from "@/components/loader/loader.vue";
import VideoLoader from "@/components/loader/VideoLoader.vue";

@Component({
    components: {VideoLoader, Loader, MaterialButton, AlertText, MaterialInput}
})
export default class InterviewView extends Vue{
    private privates:any= {}
    private carousel= {}
    private position= 0
    private isAlert= 0
    private alertText = ""
    private email= ""
    private phone= ""
    private login= ""
    private password= ""
    private repeatPassword= ""
    private lastName= ""
    private firstName= ""
    private parentStatus = ""
    private profileSchool: School | any = {}
    private cls= {}
    private isLoading = false;


    get isMobile(){
        return screen.width < 992
    }

    get width(){
        return (this.isMobile) ? '350px' : '620px'
    }

    get schoolAvatar(){
        if (this.profileSchool.avatar === null)
            return ""
        return this.profileSchool.avatar
    }

    onBeforeOpen(e: any){
        this.profileSchool = e.params.profileSchool
        this.cls = e.params.class
    }

    next1step(){
        // this.phone = this.phone.substr(0,16)
        if(this.email.length === 0 || !this.isValidEmail(this.email)){
            this.alertText = "E-mail введен некорректно"
            this.isAlert = 1
        } else if (!this.isValidPhone(this.phone)){
            this.alertText = "Некорректный номер"
            this.isAlert = 1
        }else {
            this.isAlert = 0
            this.next()
        }
    }


    next2step(){
        if (this.firstName.length === 0){
            this.alertText = "Имя не должно быть пустым"
            this.isAlert = 3
        } else if (this.lastName.length === 0){
            this.alertText = "Фамилия не должна быть пустым"
            this.isAlert = 3
        } else if (this.parentStatus == ""){
            this.alertText = "Кем вы приходитесь ребенку?"
            this.isAlert = 3
        } else {
          this.saveInformation()
        }
    }


    async onUpdateUserData(dataObject: any){
        await this.$store.dispatch("UPDATE_PARENT_INFO",{dataObject: dataObject})
        await this.$router.push({name: "ParentDashboard"})
    }

    isValidEmail(email : string){
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    isValidPhone(phone : string){
        return phone.length === 16
        // return phone.length !== 0
    }

    opened () {
        this.initCarousel({
            'main': '.js-carousel',
            'wrap': '.js-carousel__wrap',
            'prev': '.js-carousel__prev',
            'next': '.js-carousel__next'
        })
    }

    initCarousel(setting : any) {
        this.privates = {}

        /* Privates properties */
        this.privates.setting = setting

        this.privates.sel = {
            'main': document.querySelector(this.privates.setting.main),
            'wrap': document.querySelector(this.privates.setting.wrap),
            'prev': document.querySelector(this.privates.setting.prev),
            'next': document.querySelector(this.privates.setting.next)
        }

        this.privates.opt = {
            'position': 0,
            'max_position': 3
        }
    }

    next() {
        this.privates.sel.wrap.children[this.privates.opt.position].classList.add("fade-out-left")
        window.scrollTo(0,0)
        ++this.privates.opt.position
        ++this.position

        if (this.privates.opt.position >= this.privates.opt.max_position) {
            this.privates.opt.position = 0
        }

        // this.privates.sel.wrap.style['transform'] = `translateX(-${this.privates.opt.position}00%)`
        // setTimeout(() => this.privates.sel.wrap.children[this.privates.opt.position].classList.remove('hidden'), 200)

        this.privates.sel.wrap.children[this.privates.opt.position].classList.remove("fade-out-left")
        this.privates.sel.wrap.children[this.privates.opt.position].classList.add("fade-in-right")

    }

    previous() {
        this.isAlert = 0
        this.login = ""
        this.password = ""
        this.repeatPassword = ""
        this.privates.sel.wrap.children[this.privates.opt.position].classList.remove("fade-in-right")
        this.privates.sel.wrap.children[this.privates.opt.position].classList.add("fade-out-left")
        --this.privates.opt.position
        --this.position

        if (this.privates.opt.position < 0) {
            this.privates.sel.wrap.classList.add('s-notransition')
            this.privates.opt.position = this.privates.opt.max_position - 1
        }

        // this.privates.sel.wrap.style['transform'] = `translateX(-${this.privates.opt.position}00%)`
        this.privates.sel.wrap.children[this.privates.opt.position].classList.remove("fade-out-left")
        this.privates.sel.wrap.children[this.privates.opt.position].classList.add("fade-in-right")
    }


    async exit(){
      await this.$store.dispatch("EXIT_FROM_ACCOUNT");

        await this.$router.push({name: "NewAuth"})
    }

    close(){
        this.$modal.hide("InterviewModal")
    }

    saveInformation(){
        const dataObject = {
            firstName: this.firstName,
            lastName: this.lastName,
            parentStatus : this.parentStatus,
            password : this.password,
            login : this.login,
            phone : this.phone,
            email : this.email
        }

        return dataObject;
    }


    async created(){
        try{
            this.isLoading = true
            await this.$store.dispatch("GET_PROFILE")
            const profile = this.$store.getters.PROFILE
            // await this.$router.push({name: 'ParentDashboard'})

          await this.$store.dispatch("GET_SCHOOL",{ id : profile._schoolId})
          const account : School = this.$store.getters.SCHOOL

          this.onBeforeOpen({
            params:{
              profileSchool : account,
              class : profile._schoolClass
            }
          })

          this.isLoading = false
        } catch (e) {
            await this.$store.dispatch("GET_SCHOOL",{ id : e.profile._schoolId})
            const account : School = this.$store.getters.SCHOOL

            this.onBeforeOpen({
                params:{
                    profileSchool : account,
                    class : e._schoolClass
                }
            })

            this.isLoading = false

        }


    }

    async mounted(){
        this.opened()

    }
}
